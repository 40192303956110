@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;600;800&display=swap");

html,
body,
#__next {
  width: 100%;
  height: 100%;
}
